<template>
  <div>
    <Movementmenu />
    <v-container fluid>
      <base-material-card
        icon="mdi-clipboard-text"
        title="ข้อมูลสรุปผลการประมวลผลการย้าย"
        class="px-5 py-3"
      >
        <v-card class="mb-4 pa-2">
          <v-row no-gutters>
            <v-col cols="12" md="6" class="text-right pa-1">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="ค้นหา ระบุคำ หรือ ส่วนข้อความเกี่ยวข้อง"
                single-line
                hide-details
                dense
                filled
                class="mb-2"
              />
            </v-col>
            <v-col cols="12" md="2" class="pa-1">
              <v-select
                v-model="times_select"
                :items="time_ss"
                item-value="time_ss"
                label="ครั้งที่ :"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="pa-1">
              <v-select
                v-model="years_select"
                :items="year_ss"
                item-value="year_ss"
                label="ปีที่ :"
              ></v-select>
            </v-col>
            <v-col cols="12" md="2" class="pa-1">
              <v-btn color="info" large block @click="searchTimeYear()"
                >ค้นหา</v-btn
              >
            </v-col>
          </v-row>
        </v-card>
        <v-select
          v-model="selectedHeaders"
          :items="headers"
          label="เลือกคอลัมน์ที่ต้องการแสดง"
          multiple
          outlined
          return-object
        >
          <template v-slot:selection="{ item, index }">
            <v-chip v-if="index < 6">
              <span>{{ item.text }}</span>
            </v-chip>
            <span v-if="index === 6" class="grey--text caption"
              >(+{{ selectedHeaders.length - 6 }} คอลัมน์)</span
            >
          </template>
        </v-select>
        <v-data-table
          color="success"
          :headers="showHeaders"
          :items="conditons_transfer_successs"
          :search="search"
          :loading="loading"
          :item-class="row_classes"
          disable-pagination
        >
          <template v-slot:item.actionCencel="{ item }">
            <v-btn
              fab
              small
              v-if="item.ctsstatus_select === 'transfer'"
              @click="CencelMovementCut(item.ctsid_ref)"
              color="warning"
              ><v-icon>mdi-cancel</v-icon></v-btn
            >
            <v-btn
              fab
              small
              v-else
              @click="CencelMovement(item.ctsid_ref)"
              color="warning"
              ><v-icon>mdi-cancel</v-icon></v-btn
            >
          </template>

          <template v-slot:item.college_name_new="{ item }">
            <div
              style="background-color: #a7dfa2;  color: blue;"
              class="text-center ma-1"
            >
              <span
                style="font-size:16px; font-weight: bolder;"
                class="font-weight-bold"
              >
                {{ item.college_name_new }}</span
              >
            </div>
          </template>

          <template v-slot:item.id_branch="{ item }">
            <div>
              <v-chip v-if="item.id_branch" color="success">
                <v-icon>mdi-checkbox-marked-circle</v-icon>
              </v-chip>
            </div>
          </template>

          <template v-slot:item.college_name_old="{ item }">
            <div
              style="background-color: grey; font-weight: bolder; color: yellow;"
              class="text-center"
            >
              <span style="font-size:16px;" class="font-weight-bold">
                {{ item.college_name_old }}</span
              >
            </div>
          </template>

          <template v-slot:item.service_status="{ item }">
            <v-chip v-if="item.service_status === 'service'" color="yellow">
              <span style="font-size:16px;"> <v-icon>mdi-check</v-icon></span>
            </v-chip>
          </template>

          <template v-slot:item.demand_college="{ item }">
            <v-chip v-if="item.ctsstatus_select === 'demand'" color="yellow">
              <span style="font-size:16px;"> <v-icon>mdi-check</v-icon></span>
            </v-chip>
          </template>

          <template v-slot:item.ctsstatus_select="{ item }">
            <v-chip color="green" dark>
              <span
                style="font-size:16px;"
                v-if="item.ctsstatus_select === 'demand'"
              >
                ว.ประสงค์รับ</span
              >
              <span
                style="font-size:16px;"
                v-if="item.ctsstatus_select === 'agree'"
              >
                ปกติ</span
              >
              <span
                style="font-size:16px;"
                v-if="item.ctsstatus_select === 'transfer'"
              >
                ตัดโอน</span
              >
              <span
                style="font-size:16px;"
                v-if="item.ctsstatus_select === 'sw_normal'"
              >
                สับเปลี่ยน</span
              >
              <span
                style="font-size:16px;"
                v-if="item.ctsstatus_select === 'sw_agree'"
              >
                (แลกเปลี่ยน)</span
              >
            </v-chip>
          </template>
          <v-alert
            slot="no-results"
            :value="true"
            color="error"
            icon="mdi-alert"
            >ไม่พบผลลัพธ์ "{{ search }}" ที่คุณกำลังค้นหา.</v-alert
          >
        </v-data-table>
      </base-material-card>

      <!-- V-model canceldialog -->
      <v-layout row justify-center>
        <v-dialog v-model="canceldialog" persistent max-width="80%">
          <v-card class="mx-auto pa-6">
            <base-material-card
              color="warning"
              icon="mdi-clipboard-text"
              title="ยกเลิกรายการ"
              class="px-5 py-3 text_google"
            >
              <div class="text-right"></div>
            </base-material-card>
            <v-card-text>
              <v-form ref="cancelform" lazy-validation>
                {{ conditons_transfer_successs.id_cts }}
                <v-container grid-list-md>
                  <v-layout wrap>
                    <v-flex md6>
                      <p>
                        รหัสอ้างอิง :
                        {{ transference_personnels_id_ref.id_ref }}
                      </p>

                      <div class="text--center">
                        รหัสบัตรประชาชน :
                        {{ transference_personnels_id_ref.id_card }}
                        ชื่อ-นามสกุล :
                        {{ transference_personnels_id_ref.title_s
                        }}{{ transference_personnels_id_ref.frist_name }}
                        {{ transference_personnels_id_ref.last_name }}

                        สถานศึกษาปัจจุบัน :
                        {{ transference_personnels_id_ref.college_name }}
                        เลขที่ตำแหน่งปัจจุบัน :
                        {{ transference_personnels_id_ref.id_position }}
                      </div>
                    </v-flex>
                    <v-flex
                      md6
                      v-if="
                        conditons_transfer_successsSelect.ctsstatus_select ===
                          'transfer'
                      "
                    >
                      ตัดโอนตำแหน่ง ไปยัง :
                      {{ conditons_transfer_successsSelect.college_name_new }}
                    </v-flex>
                    <v-flex md6 v-else>
                      <p>
                        รหัสวิทยาลัยปลายทาง :
                        {{ man_powerss.college_code || "" }}
                      </p>
                      <div>
                        วิทยาลัย :
                        {{ man_powerss.college_name || "" }}
                      </div>
                      เลขที่ตำแหน่ง :
                      {{ man_powerss.id_position || "" }}
                    </v-flex>
                    <v-flex xs12>
                      <v-divider></v-divider>
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-form>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn large @click.stop="canceldialog = false" rounded>
                <v-icon dark>mdi-close</v-icon>ยกเลิก
              </v-btn>

              <v-btn
                v-if="
                  conditons_transfer_successsSelect.ctsstatus_select ===
                    'transfer'
                "
                large
                color="warning"
                @click.stop="cancelCutSubmit()"
                rounded
              >
                <v-icon dark>mdi-delete-forever</v-icon>&nbsp;ยืนยัน
              </v-btn>

              <v-btn
                v-else
                large
                color="warning"
                @click.stop="cancelSubmit()"
                rounded
              >
                <v-icon dark>mdi-delete-forever</v-icon>&nbsp;ยืนยัน
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-layout>
    </v-container>

    <v-container fluid>
      <v-snackbar
        v-model="snackbar.show"
        top
        :timeout="snackbar.timeout"
        :color="snackbar.color"
      >
        <v-icon large>{{ snackbar.icon }}</v-icon>
        <v-card-text>{{ snackbar.text }}</v-card-text>

        <template v-slot:action="{ attrs }">
          <v-btn text v-bind="attrs" @click="snackbar.show = false"
            >Close</v-btn
          >
        </template>
      </v-snackbar>
    </v-container>
  </div>
</template>
<script>
import Movementmenu from "../../components/admin/movementmenu.vue";

export default {
  data() {
    return {
      i: 0,
      value: 6,
      loading: false,
      ApiKey: "HRvec2021",
      branch_s: [],
      tid_ref: [],
      valid: true,
      canceldialog: false,
      positiondialog: false,
      clear_dataDialog: false,
      times_select: "",
      years_select: "",
      type_name_position: "",
      snackbar: {
        show: false,
        color: "",
        timeout: 5000,
        icon: "",
        text: ""
      },
      time_ss: [1, 2],
      year_ss: [2565, 2566, 2567, 2568, 2569, 2570],
      name_position_s: [
        { text: "สายการสอนและสนับสนุนการสอน", value: "ครู" },
        { text: "สายงานบริหารสถานศึกษา", value: "บริหาร" }
      ],
      conditons_transfer_successs: [],
      editconditons_transfer_success: {},
      search: "",
      pagination: {},
      singleSelect: false,
      selected: [],
      headers: [],
      selectedHeaders: [],
      headersMap: [
        { text: "สถานะ", align: "left", value: "ctsstatus_select" },
        { text: "ยกลิก", align: "left", value: "actionCencel" },
        { text: "ครั้งที่", align: "left", value: "ctstime_s" },
        { text: "ปีที่", align: "center", value: "ctsyear_s" },
        { text: "สังกัดใหม่", align: "center", value: "college_name_new" },
        { text: "เลขที่", align: "center", value: "ctsid_position" },
        { text: "รหัสสาขา", align: "center", value: "ctsid_branch" },
        { text: "เงื่อนไข", align: "center", value: "branch_name" },
        { text: "ตรงเงื่อนไขวิทยาลัย", align: "center", value: "id_branch" },
        { text: "รหัสบัตรประชาชน", align: "center", value: "ctsid_card" },
        { text: "คำนำหน้าชื่อ", align: "center", value: "title_s" },
        { text: "ชื่อ", align: "center", value: "frist_name" },
        { text: "นามสกุล", align: "center", value: "last_name" },
        { text: "เลขที่", align: "left", value: "ctsid_postion_old" },
        { text: "สังกัดเดิม", align: "center", value: "college_name_old" },
        {
          text: "จำนวนผู้ขอย้ายรวม",
          align: "left",
          value: "personnel_num_all"
        },
        { text: "วุฒิตรง(อันดับ)", align: "left", value: "personnel_num_ed" },
        {
          text: "จำนวนผู้ขอย้ายในสาขาเดียวกัน",
          align: "left",
          value: "personnel_num_branch"
        },
        { text: "ลำดับที่ขอย้าย", align: "left", value: "tlsequence_n" },
        {
          text: "อายุราชการในสถานศึกษาปัจจุบัน",
          align: "left",
          value: "age_app_cal"
        },
        { text: "อายุราชการตั้งแต่บรรจุ", align: "left", value: "age_app" },
        { text: "ช่วยปฏิบัติราชการ", align: "left", value: "service_status" },
        {
          text: "วิทยาลัยประสงค์รับย้าย",
          align: "left",
          value: "demand_college"
        },
        { text: "สรุป", align: "left", value: "summary_s" },
        { text: "ขาด/เกินเกณฑ์", align: "left", value: "total_s" }
      ],
      rowsperpage: [
        25,
        50,
        100,
        {
          text: "All",
          value: -1
        }
      ],
      conditons_transfer_successs: [],
      updatepositions_condition: {},
      transference_locations: [],
      periods: [],
      transference_personnels_id_ref: [],
      conditons_transfer_successsSelect: [],
      man_powerss: [],
      man_powerCan: {},
      man_power_cancel: {}
    };
  },
  async created() {
    this.headers = Object.values(this.headersMap);
    this.selectedHeaders = this.headers;
  },
  async mounted() {
    await this.periodQuery();
    await this.conditons_transfer_successQueryAll();
  },
  methods: {
    async CencelMovementCut(id_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.transference_personnels_id_ref = result_con.data;

      let result_cts = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        ctsid_ref: id_ref
      });
      this.conditons_transfer_successsSelect = result_cts.data;

      this.canceldialog = true;
    },
    async CencelMovement(id_ref) {
      let result_con = await this.$http.post("transference_personnel.php", {
        ApiKey: this.ApiKey,
        id_ref: id_ref
      });
      this.transference_personnels_id_ref = result_con.data;
      let result_man = await this.$http.post("man_power.php", {
        ApiKey: this.ApiKey,
        id_card: this.transference_personnels_id_ref.id_card
      });
      this.man_powerss = result_man.data;
      let result_cts = await this.$http.post("conditons_transfer_success.php", {
        ApiKey: this.ApiKey,
        ctsid_ref: id_ref
      });

      this.conditons_transfer_successsSelect = result_cts.data;
      this.canceldialog = true;
    },
    async cancelCutSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.conditons_transfer_successsSelect.ApiKey = this.ApiKey;
        let result_cts = await this.$http.post(
          "conditons_transfer_success.delete.php",
          this.conditons_transfer_successsSelect
        );

        if (result_cts.data.status == true) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "ยกเลิกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.conditons_transfer_successQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ยกเลิกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.conditons_transfer_successQueryAll();
        this.canceldialog = false;
      }
    },
    async cancelSubmit() {
      if (this.$refs.cancelform.validate()) {
        this.man_powerCan.ApiKey = this.ApiKey;
        this.man_powerCan.status_booking = "";
        this.man_powerCan.id_position = this.man_powerss.id_position;
        this.man_power_cancel.ApiKey = this.ApiKey;
        this.man_power_cancel.id_position = this.conditons_transfer_successsSelect.ctsid_postion_old;
        if (this.man_powerss == false) {
        } else {
          let result_man = await this.$http.post(
            "man_power.update_process.php",
            this.man_powerCan
          );
        }
        let result_man_delete = await this.$http.post(
          "man_power.delete.php",
          this.man_power_cancel
        );

        this.conditons_transfer_successsSelect.ApiKey = this.ApiKey;
        let result_cts = await this.$http.post(
          "conditons_transfer_success.delete.php",
          this.conditons_transfer_successsSelect
        );

        if (
          result_cts.data.status == true &&
          result_man_delete.data.status == true
        ) {
          this.snackbar.icon = "mdi-font-awesome";
          this.snackbar.color = "success";
          this.snackbar.text = "ยกเลิกข้อมูลเรียบร้อย";
          this.snackbar.show = true;
          this.conditons_transfer_successQueryAll();
        } else {
          this.snackbar.icon = "mdi-close-network";
          this.snackbar.color = "red";
          this.snackbar.text = "ยกเลิกข้อมูลผิดพลาด";
          this.snackbar.show = true;
        }
        this.conditons_transfer_successQueryAll();
        this.canceldialog = false;
      }
    },
    async periodQuery() {
      let result_period;
      result_period = await this.$http.post("period.php", {
        ApiKey: this.ApiKey,
        period_id: "301"
      });
      this.periods = result_period.data;
      console.log(result_period.data);
    },
    async searchTimeYear() {
      this.loading = true;
      let result = await this.$http
        .post("conditons_transfer_success.php", {
          ApiKey: this.ApiKey,
          ctstime_s: this.times_select,
          ctsyear_s: this.years_select
        })
        .finally(() => (this.loading = false));
      this.conditons_transfer_successs = result.data;
    },
    async conditons_transfer_successQueryAll() {
      this.loading = true;
      let result = await this.$http
        .post("conditons_transfer_success.php", {
          ApiKey: this.ApiKey,
          ctstime_s: this.periods.period_times,
          ctsyear_s: this.periods.period_year
        })
        .finally(() => (this.loading = false));
      this.conditons_transfer_successs = result.data;
    },
    async clear_data() {
      this.clear_dataDialog = true;
    },
    getColor(calories) {
      if (calories > 0) return "green";
      else return "";
    },
    row_classes(item) {
      if (item.ctsstatus_select == "transfer") {
        return "grey";
      }
    }
  },
  computed: {
    pages() {
      if (
        this.pagination.rowsPerPage == null ||
        this.pagination.totalItems == null
      )
        return 0;
      return Math.ceil(
        this.pagination.totalItems / this.pagination.rowsPerPage
      );
    },
    color() {
      return "green darken-4";
    },
    date_today_cal() {
      let today = new Date();
      let dd = String(today.getDate()).padStart(2, "0");
      let mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
      let yyyy = today.getFullYear();
      today = yyyy + "-" + mm + "-" + dd;
      return today;
    },
    showHeaders() {
      return this.headers.filter(s => this.selectedHeaders.includes(s));
    }
  },
  components: { Movementmenu }
};
</script>

<style scoped>
.orange {
  background-color: rgb(236, 194, 115);
}
</style>
